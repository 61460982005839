<template>
    <section class="footer-container">
        <div class="bottom-container_stamps">
            <div class="each-card">
                <div class="each-img"><img src= '../assets/Logo_Ministerio.png' alt="logo Ministerio" width="160" height="42"></div>
                Inscrito en el Registro Estatal de Empresacon el N1 1374/2019
            </div>
            <div class="each-card">
                <div class="each-img"><img src= '../assets/Logo_Banco_de_Espana_Blanco.png' alt="logo Banco España" width="200" height="66"></div>
                Registro Nº2019/D071 según Intermediarios de Crédito según Ley 05/2019
            </div>
            <div class="each-card">
                <div class="each-img"><img src= '../assets/Logo_de_aif_blanco.png' alt="logo aif" width="200" height="66" color="#93DFE7"></div>
            </div>
        </div>
        <div class="bottom-container_links">
            <div class="container-RRSS">
                <a href="https://www.facebook.com/helloteca" target="_blank">
                    <div class="RRSS-circle">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg>
                    </div>
                </a>
                <a href="https://www.instagram.com/helloteca_es/" target="_blank">
                    <div class="RRSS-circle"> 
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
                    </div>
                </a>
                <a href="https://x.com/HellotecaES" target="_blank">
                    <div class="RRSS-circle">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>                    </div>
                </a>
                <a href="https://es.linkedin.com/company/helloteca" target="_blank">
                    <div class="RRSS-circle">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"/></svg>
                    </div>
                </a> 
            </div>
            <div class="container-terminosYcondiciones">
                @2022 Helloteca  |  Política de Privacidad  |  <a href="https://stage.helloteca.com/terminos-y-condiciones/" target="_blank"  style=" text-decoration-color: #5A4FA2;  color: white;"> Términos y Condiciones</a>
            </div>
            <div class="container-logoHello">
                <div>
                    <img class="logo" src= '../assets/helloteca_white_logo.png' alt="logo Helloteca" style="margin-right: 10px;">
                    <img class="logo" src= '../assets/vivantial_blanco_logo.png' alt="logo Helloteca">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'HelloFooter',
        data: () => {
            return {
            };
        },
        computed: {
        },
        created(){
        },
        methods:{
        },
    };
</script>

<style scoped>
.footer-container{
    background: #333333;
    color: white;
}
@media (min-width: 1366px){
    .footer-container{
        padding: 0 250px;  
    }
}

/* LOGOS ESPAÑA */
.bottom-container_stamps{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 10px;
    border-bottom: 1px solid #CDD1DE;
    padding: 20px 0;
}
@media (min-width:600px){
    .bottom-container_stamps{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        border-bottom: 1px solid #CDD1DE;
        padding: 20px 0;
    }
}
.each-card{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}
@media (min-width:600px){
    .each-card{
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
.each-img {
    margin-bottom: 10px;
}

/* LOGOS RRSS */
.bottom-container_links{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 10px;
    padding: 20px 0;
}
@media (min-width:600px){
    .bottom-container_links{
        font-size: 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 20px 0;
    }
}
.container-RRSS{
    display: flex;
    flex-direction: row;
    align-items: center;
}
@media (min-width:600px){
    .container-RRSS{
        justify-content: center;
        width: 30%;
    }
}
.RRSS-circle{
    background-color: #68CAD4;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin-right: 10px;
    padding:6px;
}
svg{
    fill:white;
    width: 18px;
    height: 18px;
}
.container-terminosYcondiciones{
    width: 90%;
    padding-top: 15px;
    font-size: 10px;
    text-align: center;
}
@media (min-width:600px){
    .container-terminosYcondiciones{
        width: 30%;
        padding: 0;
    }
}
.terminosYcondiciones{
    color: white;
    text-decoration: none;
}
a{
    text-decoration: none;
}
.container-logoHello{
    display: none;
}
.container-logoHello .logo{
    max-height: 40px;
}
@media (min-width:600px){ 
    .container-logoHello{
        display: inline;
        width: 30%;
        text-align: center;
    }
}
</style>