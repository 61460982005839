<template>
    <div class="header-container">
        <img class="logo vivantial" src="../assets/vivantial_logo.png" />
        <img class="logo helloteca" src="../assets/logo_color.png"/>
    </div>
</template>

<script>
    export default {
        name: 'HelloHeader',
        data: () => {
            return {
            };
        },
        methods:{
        },
    };
</script>

<style>
.header-container{
    width:100%; 
    display: flex;
    justify-content: space-between;
    padding: 10px; 
}
.header-container .logo.helloteca{
    max-height: 50px;
}
</style>